import AboutModal from './AboutModal.json';
import Buttons from './Buttons.json';
import CineDialog from './CineDialog.json';
import Common from './Common.json';
import DatePicker from './DatePicker.json';
import Header from './Header.json';
import MeasurementTable from './MeasurementTable.json';
import SidePanel from './SidePanel.json';
import StudyBrowser from './StudyBrowser.json';
import StudyList from './StudyList.json';
import UserPreferencesModal from './UserPreferencesModal.json';
import ViewportDownloadForm from './ViewportDownloadForm.json';
import Modes from './Modes.json';
import Modals from './Modals.json'
import Local from './Local.json';
import  ToolbarButtons  from './ToolBarButtons.json';
import ViewPort from './ViewPort.json'
import  Notification  from './Notification.json';
import HotkeyField from './hotkeyField.json';
import TagBrowser from './TagBrowser.json'
import Dialog from './Dialog.json'
import commandsModule from './CommandsModule.json';
import Label from './Label.json'
import ROIThresholdConfiguration from './ROIThresholdConfiguration.json'
import PanelSUV from './PanelSUV.json'
import PatientInfo from './PatientInfo.json'
import tmvt from './TMTV.json'
import hotkeysVlidators from './hotkeysVlidators.json'
export default {
  es: {
    AboutModal,
    Buttons,
    CineDialog,
    Common,
    commandsModule,
    DatePicker,
    Dialog,
    Header,
    TagBrowser,
    MeasurementTable,
    Modals,
    PatientInfo,
    Label,
    SidePanel,
    StudyBrowser,
    StudyList,
    Modes,
    Notification,
    hotkeysVlidators,
    UserPreferencesModal,
    ViewportDownloadForm,
    Local,
    HotkeyField,
    ToolbarButtons,
    tmvt,
    ROIThresholdConfiguration,
    PanelSUV,
    ViewPort
  },
};
