import React from 'react';
import {
  Svg
} from '@ohif/ui';

  const style = {
  width: "100%",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white"
};

const logoStyle = {
  width: "350px",
  height: "auto",
  marginBottom: "30px"
}



const subtituloStyle = {
  color: "#000"
}
function WorkList({
}) {


  return (
      <div className='bg-white flex flex-col'
         style = {style}
         >
        <a href="https://med-it.app" target='_blank'>
        <Svg name="logo-pacs" style={logoStyle}/>
        </a>
        <a href="https://itestudio.com.ar" target='_blank'>
          <p style={subtituloStyle}>Desarrollado por <strong>IT Estudio</strong></p>
        </a>
    </div>
  );
}



export default WorkList;
