import React from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { Svg } from '@ohif/ui';
import { useTranslation } from 'react-i18next';
import { useAppConfig } from '@state';


const NotFound = () => {
  const { t } = useTranslation()
  const style = {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "black"
  };

  const logoStyle = {
    width: "350px",
    height: "auto"
  }
  const logoH3 = {
    fontSize: "30px",
    marginBottom: '10px'
  }

  const pStyle = {
    fontSize: "20px"
  }
  const nav = useNavigate()

  return (
    <div className='bg-white h-screen flex flex-col'
      style={style}>

      <Svg name="pacs-404" style={logoStyle} />
      <h3 style={logoH3}>Página no encontrada</h3>
      <p>Puede que el ID esté mal escrito o corresponda a un estudio que se ha eliminado</p>
        <br/>
      <a href="./" target='_self'>
        <p style={pStyle}>Volver a <strong>Inicio</strong></p>
      </a>
    </div>
  );
}

NotFound.propTypes = {
  message: PropTypes.string,
  showGoBackButton: PropTypes.bool,
};

export default NotFound;
