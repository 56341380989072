import React, { createContext, useContext, useReducer, useMemo } from 'react';
import NotFound from 'platform/viewer/src/routes/NotFound';
import {redirect} from 'react-router-dom';

// export const IMAGE_VIEWER_DEFAULT_VALUE = {
//   StudyInstanceUIDs: [],
//   setImageViewer: () => {},
// };

export const ImageViewerContext = createContext();

export function ImageViewerProvider({
  StudyInstanceUIDs,
  reducer,
  initialState,
  children,
}) {
  const value = useMemo(() => {
    return { StudyInstanceUIDs };
  }, [StudyInstanceUIDs]);

  /* if(isOID(value))*/ return (
    <ImageViewerContext.Provider value={value}>
      {children}
    </ImageViewerContext.Provider>)

}

export const useImageViewer = () => useContext(ImageViewerContext);

function isOID(value) {
  console.log(value)
  const OID_REGEX = /^(\d+\.){1,}\d+$/;
  return OID_REGEX.test(value.toString());
}
