import React from 'react';
// Svgs
import logoPacs from './../../assets/icons/pacs-logo con tagline.svg'
import pacs404 from './../../assets/icons/pacs-404.svg'
const SVGS = {
  'logo-pacs': logoPacs,
  'pacs-404': pacs404
};

/**
 * Return the matching SVG as a React Component.
 * Results in an inlined SVG Element. If there's no match,
 * return `null`
 */
export default function getSvg(key, props) {
  if (!key || !SVGS[key]) {
    return React.createElement('div', null, 'Missing SVG');
  }

  return React.createElement(SVGS[key], props);
}

export { SVGS };
